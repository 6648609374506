import React from 'react';

import Iframe from 'react-iframe';
import { Link, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';

//import Loading from '../imgs/loading.svg';

import { PageContainer } from '../styles/pages.css';
import { GlobalStyle } from '../styles/common.css';

import Footer from '../components/footer';

class Upload extends React.Component {


  askIframeHeight(ifrm) {
    if (!ifrm) {
      return;
    }
    ifrm.contentWindow.postMessage("FrameHeight", "*");
  }

  componentDidMount() {
    //handle received message by the uploader (the message contains the height of the uploader)
    let eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
    let eventer = window[eventMethod];
    let messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";

    // Listen to message from child window (from the uploader)
    eventer(messageEvent, function (e) {
      let key = e.message ? "message" : "data";
      let data = e[key];
      if (data.hasOwnProperty("FrameHeight")) {
        //Set height of the Iframe
        document.getElementsByClassName("uploader-frame")[0].style.height = data.FrameHeight + "px";
      }
      if (data.hasOwnProperty("uploadEvent")) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }, false);


    var scroll_timeout;
    let throttle = 200;
    var that = this;
    window.onresize = function () {
      if (!scroll_timeout) {
        scroll_timeout = setTimeout(function () {
          let ifrm = document.querySelector("iframe");
          that.askIframeHeight(ifrm);
          scroll_timeout = null;
        }, throttle);
      }
    }
  }


  render() {

    let uploaderFrame = {
      height: '600px'
    };
    return (
      <StaticQuery
        query={graphql`
          query {
            datoCmsPrivacy {
              cover {
                url
                fluid(maxWidth: 2000, imgixParams: { fm: "jpg", auto: "compress" }) {
                  ...GatsbyDatoCmsSizes
                }
              }
              title
              subtitle
              bodyNode {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
        `}
        render={data => {
          return (
            <>
              <GlobalStyle />
              <PageContainer>
                <Img className="cover" fluid={data.datoCmsPrivacy.cover.fluid} />
                <div className="content uploader-content">
                  <Link to="/"> ⬅ Back</Link>
                  <div className="uploader">
                    <Iframe
                      style={uploaderFrame}
                      url="https://upload.afv.com/upload-tfa"
                      title="uploader"
                      height="100%"
                      width="100%"
                      minHeight="600px"
                      scrolling="no"
                      id="uploader"
                      className="uploader-frame"
                      display="initial"
                      position="relative"
                      allowFullScreen
                      frameBorder="0"
                      ref={(f) => this.ifr = f}
                    />
                  </div>
                </div>
              </PageContainer>
              <Footer />
            </>
          )
        }}
      />
    );
  }
}

export default Upload;
